<template>
    <div>
      <!-- <PageLoader />
      <ServerError v-if="ServerError" /> -->
      <v-app id="inspire">
        <v-layout wrap justify-center class="login_page">
          <v-flex xs12 sm6 md4 lg3 px-2 align-self-center>
            <v-layout wrap justify-center>
              <v-flex xs112 xl10>
                <!-- Snackbar -->
                <v-snackbar
                  v-model="showSnackBar"
                  color="primary"
                  right
                  :timeout="timeout"
                >
                  <v-layout wrap justify-center>
                    <v-flex text-left class="align-self-center">
                      <span style="color: #fff">
                        {{ msg }}
                      </span>
                    </v-flex>
                    <v-flex text-right>
                      <v-btn
                        small
                        :ripple="false"
                        text
                        @click="showSnackBar = false"
                      >
                        <v-icon style="color: #fff">mdi-close</v-icon>
                      </v-btn>
                    </v-flex>
                  </v-layout>
                </v-snackbar>
                <!-- Login Card -->
                <v-card tile flat>
                  <v-layout wrap justify-center>
                    <v-flex px-8 py-6>
                      <v-layout wrap justify-center>
                        <v-flex xs12 text-center>
                          <span
                            style="
                              font-family: intermedium;
                              font-size: 15px;
                              color: #b8b8b8;
                            "
                          >
                            Welcome to
                          </span>
                        </v-flex>
                        <v-flex xs12 text-center py-4 style="cursor: pointer">
                          <span
                            style="
                              font-family: interbold;
                              font-size: 20px;
                              cursor: pointer;
                              color: #545454;
                            "
                          >
                            My Jesus App
                          </span>
                        </v-flex>
                        <v-flex xs12>
                          <v-layout wrap justify-center>
                            <v-flex xs6 lg4 text-center px-2>
                              <span
                                style="
                                  font-family: intersemibold;
                                  font-size: 15px;
                                  color: #000;
                                "
                              >
                                Log In
                              </span>
                              <v-progress-linear
                                height="2"
                                value="100"
                                color="#0f77db"
                              ></v-progress-linear>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex pt-8 xs12 text-left>
                          <v-layout wrap justify-center>
                            <v-flex xs12 pb-2>
                              <span
                                style="
                                  font-family: interregular;
                                  font-size: 14px;
                                  color: #ababab;
                                "
                              >
                                Email or Phone Number
                              </span>
                            </v-flex>
                            <v-flex xs12>
                              <v-form @submit.prevent="validateInput">
                                <v-text-field
                                  style="
                                    font-family: interregular;
                                    font-size: 13px;
                                    color: #ababab;
                                  "
                                  color="#717171"
                                  placeholder="Phone"
                                  outlined
                                  dense
                                  v-model="email"
                                  hide-details
                                ></v-text-field>
                              </v-form>
                            </v-flex>
                          </v-layout>
    
                          <v-layout wrap justify-center pt-2>
                            <v-flex xs12 pb-2>
                              <span
                                style="
                                  font-family: interregular;
                                  font-size: 14px;
                                  color: #ababab;
                                "
                              >
                                Password
                              </span>
                            </v-flex>
                            <v-flex xs12>
                              <v-form @submit.prevent="validateInput">
                                <v-text-field
                                  color="#717171"
                                  style="
                                    font-family: interregular;
                                    font-size: 13px;
                                    color: #ababab;
                                  "
                                  :type="showPassword ? 'text' : 'password'"
                                  placeholder="Password"
                                  outlined
                                  dense
                                  v-model="password"
                                  hide-details
                                >
                                  <template v-slot:append>
                                    <v-icon
                                      @click="togglePasswordVisibility"
                                      class="password-icon"
                                    >
                                      {{ showPassword ? 'mdi-eye' : 'mdi-eye-off' }}
                                    </v-icon>
                                  </template>
                                </v-text-field>
                              </v-form>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex pt-2 xs12 text-left>
                          <router-link to="/ForgotPassword">
                            <span
                              style="
                                font-family: intersemibold;
                                font-size: 12px;
                                text-transform: none;
                              "
                            >
                              Forgot Password ?
                            </span>
                          </router-link>
                        </v-flex>
                        <v-flex xs12 py-6>
                          <v-btn
                            block
                            tile
                            color="#0f77db"
                            dark
                            :ripple="false"
                            depressed
                            @click="validateInput"
                            class="itemValue"
                            style="
                              font-family: intermedium;
                              color: white;
                            "
                          >
                            Continue
                          </v-btn>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
    
        <!-- Footer -->
       
        <div  class="footer pt-8 pb-2">
          <span>Designed and Developed by Leopard Tech Labs</span>
        </div>
     
      </v-app>
    </div>
  </template>
  <script>
  import axios from "axios";
  import store from './../../store'
  export default {
    data() {
      return {
        ServerError: false,
        showSnackBar: false,
        timeout: 5000,
        msg: null,
        email: null,
        password: null,
        showPassword: false,
        appLoading:false,
      };
    },
    methods: {

      togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
      validateInput() {
        if (!this.email) {
          this.msg = "Please Provide Email";
          this.showSnackBar = true;
          return;
        } else if (!this.password) {
          this.msg = "Please Provide Password";
          this.showSnackBar = true;
          return;
        } else {
          this.login();
        }
      },
      login() {
        var userData = {};
        // userData.accessType = "signin";
        userData["email"] = this.email;
        userData["password"] = this.password;
        store.commit("appLoading", true);
        axios({
          method: "POST",
          url: "/user/login",
          data: userData,
        })
          .then((response) => {
            store.commit("appLoading", false);
            if (response.data.status) {
              localStorage.setItem("token", response.data.accessToken);
              localStorage.setItem("role", response.data.role);
            store.commit("loginUser", response.data.token);
            this.msg = "login successful";
            this.$router.push('/');
            } else {
              this.msg = response.data.msg;
              this.showSnackBar = true;
            }
          })
          .catch((err) => {
            store.commit("appLoading", false);
            this.ServerError = true;
            console.log(err);
          });
      },
    },
  };
  </script>
  <style>
  .login_page {
    background-color: #2aa86638;
    background-image: url("./../../assets/images/login_background.png");
    background-attachment: fixed;
    background-size: cover;
  }
.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: left;
  padding: 20px;
  font-family: intermedium;
  font-size: 15px;
  color: #030303;
}
  .password-icon {
    cursor: pointer;
  }

  </style>